<template>
  <template v-if="ppeRequests.length">
    <PpeRequestRow is-header />
    <VirtualTable :items="ppeRequests" :item-height="58" :get-key="(item) => item.id">
      <template #item="{ data: ppeRequest }">
        <PpeRequestRow
          :ppe-request="ppeRequest"
          @update:ppe-request="ppeRequests = updateCollection(ppeRequests, $event)"
          @click.stop="openedPpeRequest = ppeRequest"
        />
      </template>
    </VirtualTable>

    <LazyPpeRequestShowSheet
      v-if="openedPpeRequest"
      :ppe-request="openedPpeRequest"
      @on-close="openedPpeRequest = undefined"
      @on-change="ppeRequests = updateCollection(ppeRequests, { ...openedPpeRequest, ...$event })"
    />
  </template>

  <EmptyState v-else>
    <EmptyStateTitle>Aucune demande d'EPI...</EmptyStateTitle>
    <EmptyStateImage :src="importAsset('images/notioly/graduating.png')" />
    <Button @click="ppeRequestState.showCreateModal = true">
      <ButtonIcon :icon="mapIcon.create" />
      Créer une demande
    </Button>
  </EmptyState>
</template>

<script setup lang="ts">
import type { PpeRequest } from "@asap/shared/src/utils/supabase.types";
import { usePpeRequest } from "~/composables/usePpeRequest";

type Props = { ppeRequests: PpeRequest[] };
type Emits = { "update:ppeRequests": [value: PpeRequest[]] };

const props = defineProps<Props>();
const emit = defineEmits<Emits>();
const { ppeRequests } = useVModels(props, emit);

const { ppeRequestState } = usePpeRequest();

const openedPpeRequest = ref<PpeRequest>();
</script>
