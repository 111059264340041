<template>
  <PageContainer>
    <Title>🪖 Demandes d'EPI</Title>
    <PageHeader title="Demandes d'EPI" icon="lucide:badge-check">
      <HeaderActions>
        <Button
          :variant="ppeRequests.length ? 'primary' : 'outline'"
          :size="ppeRequests.length ? undefined : 'icon'"
          @click="ppeRequestState.showCreateModal = true"
        >
          <ButtonIcon :icon="mapIcon.create" />
          <template v-if="ppeRequests.length">Créer une demande</template>
        </Button>
      </HeaderActions>
    </PageHeader>

    <FilterRow>
      <FilterItems>
        <FilterSearch v-model="search" placeholder="Rechercher une demande d'EPI" />
        <ToggleBar>
          <ToggleBarButton
            size="small"
            :variant="ppeRequestState.selectedFilters.status === 'all' ? 'outline' : 'ghost'"
            @click="ppeRequestState.selectedFilters.status = 'all'"
          >
            {{ "Tous" }}
          </ToggleBarButton>
          <ToggleBarButton
            v-for="status in ppeRequestStatus"
            :key="status"
            size="small"
            :variant="ppeRequestState.selectedFilters.status === status ? 'outline' : 'ghost'"
            @click="ppeRequestState.selectedFilters.status = status"
          >
            {{ translatePpeRequestStatus.fr[status] }}
          </ToggleBarButton>
        </ToggleBar>
        <CalendarMonthFilterButton
          v-model:month-date="selectedDate"
          @update:month-date="ppeRequestState.selectedFilters.selectedDate = $event"
        />
      </FilterItems>
    </FilterRow>

    <PageBody>
      <PageBodyWrapper>
        <PageBodyBox>
          <PpeRequestTable
            :ppe-requests="filteredRequests"
            @update:ppe-requests="ppeRequests = updateCollectionFromList(ppeRequests, $event)"
          />
        </PageBodyBox>
      </PageBodyWrapper>
    </PageBody>

    <LazyPpeRequestCreateModal v-if="ppeRequestState.showCreateModal" @on-create="fetchPpeRequests()" />
  </PageContainer>
</template>

<script setup lang="ts">
import { getFullName } from "@asap/shared/src/utils/people";
import { translatePpeRequestStatus } from "@asap/shared/src/utils/translate";
import { usePpeRequest } from "~/composables/usePpeRequest";
import { useToast } from "~/composables/useToast";
import { ppeRequestStatus } from "~/supabase.values";
import type { PpeRequest } from "@asap/shared/src/utils/supabase.types";

const ppeRequests = ref<PpeRequest[]>([]);
const search = ref("");
const selectedDate = ref<Date>();

const { addToastError } = useToast();
const { selectMultipleForTable, ppeRequestState } = usePpeRequest();
const route = useRoute();
const router = useRouter();

const fetchPpeRequests = async () => {
  try {
    const { data } = await selectMultipleForTable(ppeRequestState.selectedFilters);
    if (data) ppeRequests.value = data;
  } catch (error) {
    addToastError(
      {
        title: "Il y a eu une erreur lors du chargement des demandes d'EPI",
      },
      error
    );
  }
};

const { results } = useFuse(search, ppeRequests, {
  fuseOptions: {
    keys: [
      {
        name: "talent",
        getFn: (ppeRequest) =>
          ppeRequest.contract.talent ? getFullName(ppeRequest.contract.talent).toLocaleLowerCase() : "",
      },
      {
        name: "status",
        getFn: (ppeRequest) => translatePpeRequestStatus.fr[ppeRequest.status].toLocaleLowerCase(),
      },
      {
        name: "owner",
        getFn: (ppeRequest) => (ppeRequest.owner ? getFullName(ppeRequest.owner).toLocaleLowerCase() : ""),
      },
    ],
    threshold: 0.2,
  },
  matchAllWhenSearchEmpty: true,
});

const filteredRequests = computed(() => {
  return results.value.map((result) => result.item);
});

watch(filteredRequests, () => {
  switch (route.query.status) {
    case "to_validate":
      ppeRequestState.selectedFilters.status = "to_validate";
      break;
    case "todo":
      ppeRequestState.selectedFilters.status = "todo";
      break;
  }

  router.replace({ query: { status: undefined } });
});

watchEffect(fetchPpeRequests);
</script>
